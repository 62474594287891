import React from 'react';
import PropTypes from 'prop-types';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TooltipTypography from '@fingo/lib/components/typography/TooltipTypography';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottom from '@mui/icons-material/HourglassBottom';
import PsychologyIcon from '@mui/icons-material/Psychology';

const ICON_MAPPING = {
  OFFERED: { icon: <CheckCircleIcon color="success" />, label: 'Aceptada' },
  REJECTED: { icon: <CancelIcon color="error" />, label: 'Rechazada' },
  PENDINGDOCUMENTS: {
    icon: <HourglassBottom color="primary" />,
    label: 'Pendiente de Documentos',
  },
  DOCUMENTSREVIEWED: {
    icon: <PsychologyIcon color="info" />,
    label: 'Revisión de Documentos',
  },
  DEFAULT: { icon: <PsychologyIcon color="info" />, label: 'Evaluando' },
};

const EvaluationStatusCell = ({ status }) => {
  const { icon, label } = ICON_MAPPING[status] || ICON_MAPPING.DEFAULT;

  return (
    <Stack direction="row" alignItems="center">
      <TooltipTypography title={label}>{icon}</TooltipTypography>
      <Typography>{label}</Typography>
    </Stack>
  );
};

EvaluationStatusCell.propTypes = {
  status: PropTypes.string.isRequired,
};

export default EvaluationStatusCell;
