import React from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '@fingo/lib/hooks/useIsMobile';
import NationalTaxServiceCredentialsForm from '@fingo/lib/components/forms/NationalTaxServiceCredentialsForm';
import LazyImage from '@fingo/lib/components/images/LazyImage';
import LazyLoadImage from '@fingo/lib/components/images/LazyLoadImage';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const NationalTaxServiceCredentialsOverlay = () => {
  const isMobile = useIsMobile('desktop');
  const { t } = useTranslation();

  return (
    <Stack
      direction="row"
      alignItems={{ mobile: 'flex-start', desktop: 'center' }}
      px={{ mobile: 2, desktop: 0 }}
      mt={{ mobile: 4, desktop: 0 }}
      height="100%"
      justifyContent="center"
      spacing={{ desktop: 3, lg: 4 }}
      zIndex={1}
      position="relative"
    >
      {!isMobile && (
        <LazyImage
          imagePath="images/overlays/sii-overlay.png"
          alt="Sii Overlay"
          width={{ desktop: 450, lg: 517 }}
        />
      )}
      <Stack spacing={{ mobile: 2, tablet: 4, desktop: 5 }}>
        <LazyLoadImage
          src={t('National Tax Service logo')}
          alt="Sii Logo"
          width={{ mobile: 50, desktop: 60, lg: 70 }}
          height={{ mobile: 50, desktop: 60, lg: 70 }}
        />
        <Box>
          <Typography
            color="black"
            fontSize={{ mobile: 10, tablet: 14, desktop: 12, lg: 16 }}
            fontWeight={500}
            textAlign={{ mobile: 'center', desktop: 'left' }}
          >
            Para acceder a financiamiento de facturas y a los productos de Fingo
          </Typography>
          <Typography
            color="primary"
            fontSize={{ mobile: 18, tablet: 26, desktop: 22 }}
            textAlign={{ mobile: 'center', desktop: 'left' }}
            fontWeight={600}
          >
            Ingresa tus credenciales del {t('National Tax Service acronym')}
          </Typography>
        </Box>
        <NationalTaxServiceCredentialsForm autocomplete />
      </Stack>
    </Stack>
  );
};

export default NationalTaxServiceCredentialsOverlay;
