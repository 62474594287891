import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconStackOverlay from '@fingo/lib/components/overlays/IconStackOverlay';

const EvaluationInvoiceOverlay = () => (
  <IconStackOverlay>
    <Typography variant="h4" paragraph>
      Aún no posees facturas en evaluación
    </Typography>
    <Typography variant="body1" paragraph>
      Envía a evaluar en la{' '}
      <Link component={RouterLink} to="/app/sales/factoring/available">
        pestaña de facturas disponibles
      </Link>{' '}
      y obtén una evaluación rápida. ¡Desbloquea el potencial de tu negocio hoy
      mismo!
    </Typography>
  </IconStackOverlay>
);

export default EvaluationInvoiceOverlay;
