import React from 'react';
import useSelectedCompany from '@fingo/lib/hooks/useSelectedCompany';
import Typography from '@mui/material/Typography';
import IconStackOverlay from '@fingo/lib/components/overlays/IconStackOverlay';
import NationalTaxServiceCredentialsOverlay from './NationalTaxServiceCredentialsOverlay';

const AvailableFactoringOverlay = () => {
  const company = useSelectedCompany();
  const hasSiiCredentials = company?.hasSiiCredentials;

  if (hasSiiCredentials) {
    return (
      <IconStackOverlay>
        <Typography variant="h4" paragraph>
          No tiene facturas disponibles para mostrar
        </Typography>
        <Typography variant="body1" paragraph>
          Aquí se mostrarán tus facturas cuando estén disponibles.
        </Typography>
      </IconStackOverlay>
    );
  }

  return <NationalTaxServiceCredentialsOverlay />;
};

export default AvailableFactoringOverlay;
