import { gql } from '@apollo/client';
import {
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '@fingo/lib/graphql/fragments/invoice-input';
import CollectionActionMinimalFields from '@fingo/lib/graphql/fragments/collection-action/collection-action-minimal-fields';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import MasterEntityMinimalFields from '@fingo/lib/graphql/fragments/master-entity/master-entity-minimal-fields';
import OfferMinimalFields from '@fingo/lib/graphql/fragments/offer/offer-minimal-fields';
import NoteFields from '@fingo/lib/graphql/fragments/invoice/note-fields';
import ReferenceFields from '@fingo/lib/graphql/fragments/invoice/reference-fields';

const CLIENT_ASSIGNMENTS = gql`
  query clientAssignments(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      totalCount
      edges {
        cursor
        node {
          id
          folio
          dateIssued
          dateToPay
          status
          uniqueDocumentFinanceState {
            id
            status {
              id
              code
            }
          }
          amountWithIva {
            ...MoneyFields
          }
          company {
            id
            masterEntity {
              id
            }
          }
          receiver {
            ...MasterEntityMinimalFields
          }
          paymentDiscounts {
            id
            paymentAmountAfterNegativeSurplus {
              ...MoneyFields
            }
          }
          offer {
            ...OfferMinimalFields
          }
          historystatusSet {
            id
            status
            createdAt
          }
          lastHistoryStatus {
            id
            status
          }
          collectionManager {
            id
            actions {
              ...CollectionActionMinimalFields
            }
          }
          references {
            ...ReferenceFields
          }
          creditNotes @client {
            ...NoteFields
          }
          debitNotes @client {
            ...NoteFields
          }
          lightningPaymentAuthorized @client
        }
      }
    }
  }
  ${CollectionActionMinimalFields}
  ${MoneyFields}
  ${MasterEntityMinimalFields}
  ${OfferMinimalFields}
  ${ReferenceFields}
  ${NoteFields}
`;

export default CLIENT_ASSIGNMENTS;
