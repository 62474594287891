import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const TransferConditionCell = ({ offer }) => {
  const isWithRestriction = offer.transferCondition === 'WITH_RATIFICATION';
  const message = isWithRestriction
    ? 'Contactaremos a tu cliente para validar esta factura'
    : 'Esta factura está lista para ser girada';

  return (
    <Tooltip title={message}>
      <Typography color={isWithRestriction ? 'info.main' : 'text.primary'}>
        {isWithRestriction ? 'Con ratificación' : 'Sin ratificación'}
      </Typography>
    </Tooltip>
  );
};

TransferConditionCell.propTypes = {
  offer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    transferCondition: PropTypes.oneOf([
      'WITH_RATIFICATION',
      'WITHOUT_RATIFICATION',
    ]).isRequired,
  }).isRequired,
};

export default TransferConditionCell;
