import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import IconStackOverlay from '@fingo/lib/components/overlays/IconStackOverlay';

const AvailableOffersOverlay = () => (
  <IconStackOverlay>
    <Typography variant="h4" paragraph>
      No posees facturas con oferta disponibles
    </Typography>
    <Typography variant="body1" paragraph>
      Revisa{' '}
      <Link component={RouterLink} to="/app/sales/factoring/available">
        tus facturas disponibles
      </Link>{' '}
      para enviar a evaluar.
    </Typography>
  </IconStackOverlay>
);

export default AvailableOffersOverlay;
