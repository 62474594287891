import React, { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {
  preofferSelected,
  isInvoicesFirstLoadVar,
} from '@fingo/lib/apollo/reactive-variables/invoices';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import DocumentList from '@fingo/lib/components/lists/DocumentList';
import { getHelpVideoLink } from '@fingo/lib/constants/help-tooltip-content';
import AVAILABLE_FACTORING from '@fingo/lib/graphql/factoring/queries';
import useNewInvoiceCharged from '@fingo/lib/hooks/useNewInvoiceCharged';
import COLUMNS from '../constants/available-factoring';
import AvailableFactoringOverlay from '../components/overlays/AvailableFactoringOverlay';

const AvailableFactoring = () => {
  const selectedPreofferIds = useReactiveVar(preofferSelected);
  const isInvoicesFirstLoad = useReactiveVar(isInvoicesFirstLoadVar);
  const currency = useReactiveVar(currencyVar);

  const [newInvoices, readNewInvoices] = useNewInvoiceCharged();

  const isRowSelectable = useCallback(
    ({ row }) => row.preofferSelectable?.selectable,
    [],
  );

  return (
    <DocumentList
      initialOrderBy="preoffer_MonthlyRate"
      trackerId="PREOFFERS"
      type="available-factoring"
      headerTitle="Financiamiento de facturas"
      queryDocument={AVAILABLE_FACTORING}
      customVariables={{
        currency,
      }}
      showFilters
      emitter
      checkboxSelection
      countryFilter
      shoppingCartPreview
      newData={newInvoices}
      readData={readNewInvoices}
      isRowSelectable={isRowSelectable}
      firstLoad={isInvoicesFirstLoad}
      includeHeaders={[
        'folio',
        'dteType_Code',
        'dateIssued',
        'receiver_sortable',
        'amountWithIva',
        'preoffer_MonthlyRate',
        'defaultRate',
        'factoringStatus',
        'uploadDocuments',
      ]}
      mobileHeaders={[
        'folio',
        'receiver_sortable',
        'preoffer_MonthlyRate',
        'amountWithIva',
      ]}
      financingStatusOptions
      initialInvoiceFilters={{
        availableForFinancing: 'AVAILABLE',
        preoffer_Preofferevaluationrequest_Isnull: true,
      }}
      onSelectionModelChange={preofferSelected}
      selectionModel={selectedPreofferIds}
      keepNonExistentRowsSelected
      preColumns={COLUMNS}
      helpVideo={getHelpVideoLink('available-factoring')}
      DataGridNoRowsOverlayComponent={AvailableFactoringOverlay}
      updateCustomVariables
    />
  );
};

export default AvailableFactoring;
