import { gql } from '@apollo/client';
import {
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '@fingo/lib/graphql/fragments/invoice-input';
import NoteFields from '@fingo/lib/graphql/fragments/invoice/note-fields';
import ReferenceFields from '@fingo/lib/graphql/fragments/invoice/reference-fields';
import CompanyEvaluationRestrictionMinimalFields from '@fingo/lib/graphql/fragments/company-evaluation-restriction/company-evaluation-restriction-minimal-fields';
import MasterEntityMinimalFields from '@fingo/lib/graphql/fragments/master-entity/master-entity-minimal-fields';
import InvoiceDteTypeMinimalFields from '@fingo/lib/graphql/fragments/invoice-dte-type/invoice-dte-type-minimal-fields';
import MoneyFields from '@fingo/lib/graphql/fragments/money/money-fields';
import OfferMinimalFields from '@fingo/lib/graphql/fragments/offer/offer-minimal-fields';
import ExecutiveFields from '@fingo/lib/graphql/fragments/user/executive-fields';

const MY_OFFERS = gql`
  query myOffers(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      totalCount
      edges {
        cursor
        node {
          id
          folio
          dateIssued
          siiStatus
          loanedStatus
          loanedToRut
          loanedTo
          creditNoteStatus
          isForOrdering
          hasPaymentsEmitted
          status
          dateExpiration
          amountWithIva {
            ...MoneyFields
          }
          dteType {
            ...InvoiceDteTypeMinimalFields
          }
          company {
            id
            executiveAssigned {
              ...ExecutiveFields
            }
          }
          receiver {
            ...MasterEntityMinimalFields
          }
          preoffer {
            id
            preofferevaluationrequest {
              id
              status
            }
          }
          offer {
            ...OfferMinimalFields
          }
          operation {
            id
          }
          restrictedBy {
            ...CompanyEvaluationRestrictionMinimalFields
          }
          references {
            ...ReferenceFields
          }
          creditNotes @client {
            ...NoteFields
          }
          debitNotes @client {
            ...NoteFields
          }
          preofferSelectable @client
        }
      }
    }
  }
  ${NoteFields}
  ${ReferenceFields}
  ${CompanyEvaluationRestrictionMinimalFields}
  ${MasterEntityMinimalFields}
  ${InvoiceDteTypeMinimalFields}
  ${MoneyFields}
  ${OfferMinimalFields}
  ${ExecutiveFields}
`;

export default MY_OFFERS;
