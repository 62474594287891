import React from 'react';
import AmountWithIvaCell from '@fingo/lib/components/cells/AmountWithIvaCell';
import CompanyCell from '@fingo/lib/components/cells/CompanyCell';
import DateCell from '@fingo/lib/components/cells/DateCell';
import FolioCell from '@fingo/lib/components/cells/FolioCell';
import formatDecimal from '@fingo/lib/helpers/formatters';
import PaymentAmountAfterNegativeSurplusCell from '@fingo/lib/components/cells/PaymentAmountAfterNegativeSurplus';
import DateWithDaysDiffCell from '@fingo/lib/components/dataGridCells/DateWithDaysDiffCell';
import CessionStatus from '@fingo/lib/components/dataGridCells/CessionStatus';
import ActionDrawerCell from '@fingo/lib/components/cells/ActionDrawerCell';
import DrawerHeader from '@fingo/lib/components/drawer/DrawerHeader';
import UserActions from '@fingo/lib/components/drawer/UserActions';

const COLUMNS = [
  {
    field: 'folio',
    headerName: 'Folio',
    disableColumnMenu: true,
    width: 90,
    renderCell: ({ row }) => <FolioCell invoice={row} />,
  },
  {
    field: 'receiver_sortable',
    headerName: 'Receptor',
    disableColumnMenu: true,
    flex: 1,
    renderCell: ({ row }) => <CompanyCell company={row.receiver} />,
  },
  {
    field: 'dateIssued',
    headerName: 'Emisión',
    disableColumnMenu: true,
    width: 105,
    renderCell: ({ row }) => <DateCell date={row.dateIssued} />,
  },
  {
    field: 'amountWithIva',
    headerName: 'Monto',
    disableColumnMenu: true,
    width: 110,
    renderCell: ({ row }) => <AmountWithIvaCell row={row} />,
  },
  {
    field: 'offer_MonthlyRate',
    headerName: 'Tasa',
    sortable: false,
    disableColumnMenu: true,
    width: 75,
    valueGetter: ({ row }) => {
      const { offer } = row;
      return formatDecimal(offer.monthlyRate);
    },
  },
  {
    field: 'offer_DefaultRate',
    headerName: 'Mora',
    sortable: false,
    disableColumnMenu: true,
    width: 60,
    valueGetter: ({ row }) => {
      const { offer } = row;
      return formatDecimal(offer.defaultRate);
    },
  },
  {
    field: 'paymentAmountAfterNegativeSurplus',
    headerName: 'Monto a transferir',
    disableColumnMenu: true,
    width: 155,
    renderCell: ({ row }) => (
      <PaymentAmountAfterNegativeSurplusCell
        money={row.paymentDiscounts?.paymentAmountAfterNegativeSurplus}
      />
    ),
  },
  {
    field: 'operationDateToPay',
    headerName: 'Vencimiento',
    sortable: false,
    disableColumnMenu: true,
    width: 140,
    renderCell: ({ row }) => (
      <DateWithDaysDiffCell
        date={row.dateToPay}
        status={row.status}
        useColors
      />
    ),
  },
  {
    field: 'cessionStatus',
    headerName: 'Estado de operación',
    sortable: false,
    disableColumnMenu: true,
    width: 150,
    renderCell: (params) => <CessionStatus invoice={params.row} />,
  },
  {
    field: 'actionsDrawer',
    headerName: 'Cobranza',
    type: 'actions',
    sortable: false,
    width: 80,
    renderCell: ({ row }) => {
      const actions = row.collectionManager?.actions?.filter(
        (action) => action.actionType !== 'OTHER',
      );
      return (
        <ActionDrawerCell
          title={`Gestiones de cobranza factura ${row.folio}`}
          actions={actions}
          headerComponent={DrawerHeader}
          contentComponent={UserActions}
          drawerId={row.folio}
        />
      );
    },
  },
];

export default COLUMNS;
