import React from 'react';
import { useReactiveVar } from '@apollo/client';
import { shoppingCartOffersVar } from '@fingo/lib/apollo/reactive-variables/shopping-cart';
import currencyVar from '@fingo/lib/apollo/reactive-variables/money';
import { DocumentList } from '@fingo/lib/components/lists';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import AvailableOffersOverlay from '../components/overlays/AvaliableOffersOverlay';
import { COLUMNS, HEADERS_BY_COUNTRY } from '../constants/available-offers';
import MY_OFFERS from '../graphql/queries/my-offers';

const AvailableOffers = () => {
  const country = useGetCountryFromUrl();
  const shoppingCartOffers = useReactiveVar(shoppingCartOffersVar);
  const offerIds = shoppingCartOffers.map((offer) => offer.invoiceId);
  const currency = useReactiveVar(currencyVar);
  const headers = HEADERS_BY_COUNTRY[country.name];

  return (
    <DocumentList
      trackerId="OFFERS"
      type="available-offers"
      headerTitle="Ofertas"
      queryDocument={MY_OFFERS}
      checkboxSelection
      shoppingCartPreview
      emitter
      countryFilter
      includeHeaders={headers}
      mobileHeaders={[
        'folio',
        'receiver_sortable',
        'offerMonthlyRate',
        'offerDefaultRate',
        'dateToPay',
      ]}
      customVariables={{
        currency,
        hasValidOffer: true,
        dateIssued_Gte: null,
        dateIssued_Lte: null,
      }}
      onSelectionModelChange={(ids) => {
        const newSelectedInvoices = ids.map((id) => {
          const existingOffer = (
            shoppingCartOffers.find(({ invoiceId }) => invoiceId === id)
          );
          return existingOffer || { invoiceId: id, dateToPay: null };
        });
        shoppingCartOffersVar(newSelectedInvoices);
      }}
      selectionModel={offerIds}
      keepNonExistentRowsSelected
      preColumns={COLUMNS}
      isRowSelectable={({ row }) => !row.isForOrdering}
      isCellEditable={({ row }) => !row.isForOrdering}
      DataGridNoRowsOverlayComponent={AvailableOffersOverlay}
    />
  );
};

export default AvailableOffers;
